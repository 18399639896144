/* Dashboard */
.domain-identity{
  padding:1em;
}
code {
  color: white;
  font-family: monospace;
  background: #303030;
  border: 1px dashed white;
  margin-left: 20px;
  padding: 5px 10px;
}