.users-container{
    padding:1em;
    thead{
        border-top: 2px solid #292929;
    }
}
.card-title{
    h2{
        flex:1
    }
    width:100%;
    display:flex;
    align-items: center;
    justify-content: space-between;
}