/* You can add global styles to this file, and also import other style files */

/* === Plugin styles === */

:root {
    --dfBackgroundColor: rgba(198, 198, 198, 1);
    --dfBackgroundSize: 7px;
    --dfBackgroundImage: linear-gradient(to right, rgba(214, 214, 214, 1) 1px, transparent 1px), linear-gradient(to bottom, rgba(214, 214, 214, 1) 1px, transparent 1px);
  
    --dfNodeType: flex;
    --dfNodeTypeFloat: none;
    --dfNodeBackgroundColor: rgba(255, 255, 255, 1);
    --dfNodeTextColor: #000000;
    --dfNodeBorderSize: 1px;
    --dfNodeBorderColor: rgba(176, 176, 176, 1);
    --dfNodeBorderRadius: 4px;
    --dfNodeMinHeight: 35px;
    --dfNodeMinWidth: 150px;
    --dfNodePaddingTop: 5px;
    --dfNodePaddingBottom: 5px;
    --dfNodeBoxShadowHL: 0px;
    --dfNodeBoxShadowVL: 2px;
    --dfNodeBoxShadowBR: 15px;
    --dfNodeBoxShadowS: 2px;
    --dfNodeBoxShadowColor: rgba(0, 0, 0, 0.38);
  
    --dfNodeHoverBackgroundColor: #ffffff;
    --dfNodeHoverTextColor: #000000;
    --dfNodeHoverBorderSize: 2px;
    --dfNodeHoverBorderColor: #000000;
    --dfNodeHoverBorderRadius: 4px;
  
    --dfNodeHoverBoxShadowHL: 0px;
    --dfNodeHoverBoxShadowVL: 2px;
    --dfNodeHoverBoxShadowBR: 15px;
    --dfNodeHoverBoxShadowS: 2px;
    --dfNodeHoverBoxShadowColor: #4ea9ff;
  
    --dfNodeSelectedBackgroundColor: #000000;
    --dfNodeSelectedTextColor: #ffffff;
    --dfNodeSelectedBorderSize: 2px;
    --dfNodeSelectedBorderColor: #000000;
    --dfNodeSelectedBorderRadius: 4px;
  
    --dfNodeSelectedBoxShadowHL: 0px;
    --dfNodeSelectedBoxShadowVL: 2px;
    --dfNodeSelectedBoxShadowBR: 15px;
    --dfNodeSelectedBoxShadowS: 2px;
    --dfNodeSelectedBoxShadowColor: #4ea9ff;
  
    --dfInputBackgroundColor: #ffffff;
    --dfInputBorderSize: 2px;
    --dfInputBorderColor: #000000;
    --dfInputBorderRadius: 50px;
    --dfInputLeft: -27px;
    --dfInputHeight: 20px;
    --dfInputWidth: 20px;
  
    --dfInputHoverBackgroundColor: #ffffff;
    --dfInputHoverBorderSize: 2px;
    --dfInputHoverBorderColor: #000000;
    --dfInputHoverBorderRadius: 50px;
  
    --dfOutputBackgroundColor: #ffffff;
    --dfOutputBorderSize: 2px;
    --dfOutputBorderColor: #000000;
    --dfOutputBorderRadius: 50px;
    --dfOutputRight: -3px;
    --dfOutputHeight: 20px;
    --dfOutputWidth: 20px;
  
    --dfOutputHoverBackgroundColor: #ffffff;
    --dfOutputHoverBorderSize: 2px;
    --dfOutputHoverBorderColor: #000000;
    --dfOutputHoverBorderRadius: 50px;
  
    --dfLineWidth: 5px;
    --dfLineColor: #4682b4;
    --dfLineHoverColor: #4682b4;
    --dfLineSelectedColor: #43b993;
  
    --dfRerouteBorderWidth: 2px;
    --dfRerouteBorderColor: #000000;
    --dfRerouteBackgroundColor: #ffffff;
  
    --dfRerouteHoverBorderWidth: 2px;
    --dfRerouteHoverBorderColor: #000000;
    --dfRerouteHoverBackgroundColor: #ffffff;
  
    --dfDeleteDisplay: block;
    --dfDeleteColor: #ffffff;
    --dfDeleteBackgroundColor: #000000;
    --dfDeleteBorderSize: 2px;
    --dfDeleteBorderColor: #ffffff;
    --dfDeleteBorderRadius: 50px;
    --dfDeleteTop: -15px;
  
    --dfDeleteHoverColor: #000000;
    --dfDeleteHoverBackgroundColor: #ffffff;
    --dfDeleteHoverBorderSize: 2px;
    --dfDeleteHoverBorderColor: #000000;
    --dfDeleteHoverBorderRadius: 50px;
  
  }

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "../assets/styles/variables";
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";
@import "~bootstrap/scss/bootstrap";
@import "../assets/styles/fonts";
@import "../assets/styles/functions";

/* === Icon fonts === */
@import "~@mdi/font/scss/materialdesignicons";


/* === Template mixins === */
@import "../assets/styles/mixins/animation";
@import "../assets/styles/mixins/badges";
@import "../assets/styles/mixins/buttons";
@import "../assets/styles/mixins/misc";
@import "../assets/styles/mixins/color-functions";
@import "../assets/styles/mixins/cards";
@import "../assets/styles/mixins/blockqoute";


/* === Core Styles === */
@import "../assets/styles/typography";
@import "../assets/styles/reset";
@import "../assets/styles/responsive";
@import "../assets/styles/misc";
@import "../assets/styles/utilities";
@import "../assets/styles/demo";
@import "../assets/styles/spinner";
@import "../assets/styles/dashboard";
@import "../assets/styles/emaileditor"; 
@import "../assets/styles/allcampaigns"; 
@import "../assets/styles/alltemplates"; 
@import "../assets/styles/campaign-editor"; 
@import "../assets/styles/allgroups"; 
@import "../assets/styles/allsubscribers"; 
@import "../assets/styles/allautomations"; 
@import "../assets/styles/allusers"; 

/* === Components === */

@import "../assets/styles/components/checkbox-radio";
@import "../assets/styles/components/forms";
@import "../assets/styles/components/icons";
@import "../assets/styles/components/tables";
@import "../assets/styles/components/buttons";
@import "../assets/styles/components/breadcrumbs";
@import "../assets/styles/components/badges";
@import "../assets/styles/components/cards";
@import "../assets/styles/components/preview";
@import "../assets/styles/components/lists";
@import "../assets/styles/components/todo-list";
@import "../assets/styles/components/dropdowns";
@import "../assets/styles/components/loaders/loaders";

@import "../assets/styles/components/landing-screens/auth";

/* === Plugin Overrides === */
@import "../assets/styles/components/plugin-overrides/slick-carousel";
@import "../assets/styles/components/plugin-overrides/jquery-jvectormap";

/* === Layout === */
@import "../assets/styles/navbar";
@import "../assets/styles/sidebar";
@import "../assets/styles/footer";
@import "../assets/styles/layout";


@import "../assets/styles/floweditor";
@import "../assets/styles/domainidentity";


.gjs-pn-panel.gjs-pn-custom-panel {
    margin-left: 20vw;
}
.gjs-toolbar-item.floppy-save i {
    vertical-align: middle;
    text-align: center;
    width: 100%;
}

.react-loading-skeleton{
    --base-color: #2c2e33!important;
    --highlight-color: #51545b!important;
}

td, th {
    color: white;
}

span.tag-chip {
    font-family: monospace;
    letter-spacing: -0em;
    font-size: 14px;
    background: grey;
    padding: 5px 5px;
    cursor: pointer;
    border-radius: 20px;
    color: white;
    margin-right: 3px;
}
div#swal2-html-container {
    min-height: 120px;
}
.sidebar .nav .nav-item.nav-category .nav-link, .sidebar .nav .nav-item .nav-link .menu-title{
    color:#fff;
}
.text-muted, .preview-list .preview-item .preview-item-content p .content-category {
    color: #9ba0b7 !important;
}

.form-control:disabled, .form-control[readonly] {
    background: #5c5c5c;
}

/** 
Hide unwanted command buttns from editor
*/
.gjs-pn-options span.gjs-pn-btn:nth-of-type(4), .gjs-pn-options span.gjs-pn-btn:nth-of-type(5), .gjs-pn-options span.gjs-pn-btn:nth-of-type(6) {
    display: none;
}
.disabled{
    opacity: .3; 
    pointer-events: none
}

div#campaignGroup {
    padding: 0;
    background: transparent;
    border: 0;
    align-items: center;
    display: flex;
    justify-content: center;
}
div#campaignGroup > div {
    width: 100%;
    background: #272c33;
}
div#roleSelect {
    padding: 0;
    background: transparent;
    border: 0;
    align-items: center;
    display: flex;
    justify-content: center;
}
div#roleSelect > div {
    width: 100%;
    background: #272c33;
}
div#roleSelect div {
    color:white!important;
}
.select2box .form-control {
    padding: 0;
    background: transparent;
    border: 0;
}
.select2 [class*='control'] {
    background: #191c24;
}
.react-flow__attribution{
    display: none;
}

div#gjs-clm-tags-field, .gjs-clm-sels-info, #gjs-clm-up {
    display: none !important;
}
.btn-success.fa-floppy-o{
    background-color: green;;
}
:root {
    --gjs-left-width: 20%;
}

.form-control:focus {
    border: 1px solid rgb(46 99 247)!important;
    color: white !important;
}