.drawflow .drawflow-node {
  background: #c6d5ff!important;
  width: 220px!important;
  border-radius: 10px!important;
  border: 1px dashed #000!important;
}
.drawflow .drawflow-node.selected {
  background: #f5c1c1!important;
}
.drawflow .drawflow-node .input{
  background: #2e63f7!important;
  border-color: #c6d5ff!important;
}
.drawflow-delete {
  right: -10px !important;
  top: -10px !important;
  width: 20px !important;
  height: 20px !important;
  background: #d13131 !important;
  line-height: 15px !important;
  font-family: sans-serif !important;
  text-transform: lowercase !important;
}

.drag-drawflow[data-node] {
    padding: 10px;
    display: flex;
    margin:10px 0!important;
    min-height:50px;
    border-bottom: 1px solid white;
    align-items:center;
    display:flex;
  svg{
    width:25px;
    margin:0 10px;
  }   
}

.flow-sidebar h3 {
  margin: 15px 40px;
  font-size:20px;
}

.drawflow_content_node{
  select, input{
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid #fdfdfd;
  }
}

.drawflow-node.add-condition {
  min-width: 450px;
}
.drawflow .drawflow-node.add-condition .output {
  width: 60px;
  height: 30px;
  border-radius: 10px;
}
.add-condition div.input_1{
  left: 92px;
    top: 5px;
}
.add-condition div.output.output_1:before {
  content: 'THEN';
  top: 2px;
  left: 6px;
  position: absolute;
}
.add-condition div.output.output_2:before {
  content: 'ELSE';
  top: 2px;
  left: 8px;
  position: absolute;
}
.drawflow .drawflow-node.add-condition .output{
  right: -73px;
}
.flow-save-btn.btn.btn-success {
  width: 100%;
  margin-top: 10vh;
}
.drag-drawflow small {
  font-style: italic;
  font-size: 12px;
}

.drawflow .drawflow-node {
  display: block!important;
}

.drawflow .drawflow-node .inputs, .drawflow .drawflow-node .outputs {
  display: flex!important;
  width: auto!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}

.drawflow .drawflow-node .inputs {
  position: absolute!important;
    top: -15px !important;
    left: 120px !important;
}

.drawflow .drawflow-node .outputs {
  position: absolute;
  bottom: -15px!important;
  left: 100px!important;;
}