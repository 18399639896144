.content-wrapper{
    padding:0em;
}
.cfemail-editor-wrapper{
    min-height: 85vh;;
    div:first-of-type:not(.cfemail-editor-buttons){
        min-height:85vh;
    }
    .cfemail-editor-buttons{
        display:flex;
        justify-content: end;;
        padding:1em;
        margin-bottom: 20px;;

    }
    .blockbuilder-branding{
        display:none!important;
    }
}
.gjs-toolbar{
    left:0!important;
}